import React, { useState, useEffect } from "react";
import linksNav from "../constants/linksNav";
import { Link } from "gatsby";
import { FaTimes } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";
import Modal from "react-modal";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <>
      <aside className={isOpen ? "sidebar show-sidebar" : "sidebar"}>
        <button className="close-btn" type="button" onClick={toggleSidebar}>
          <FaTimes />
        </button>
        <div className="side-container">
          <ul className={isOpen ? "sidebar-links" : null}>
            <>
              {linksNav.map((link) => {
                return (
                  <li key={link.id}>
                    <Link to={link.url} onClick={toggleSidebar}>
                      {link.text}
                    </Link>
                  </li>
                );
              })}
            </>

            <li>
              <a onClick="">Acceso para Empresas</a>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
