const data = [
  {
    id: 0,
    text: "Inicio",
    url: "/",
  },
  {
    id: 1,
    text: "¿Qué es +G?",
    url: "/about",
  },
  {
    id: 2,
    text: "Eventos",
    url: "/events",
  },
  {
    id: 3,
    text: "Marco de Referencia",
    url: "/norma-iso",
  },


]

export default data
