import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <footer id="footer">

        <div className="footer-container">
          <div className="footer-info">
            <h3>Contacto</h3>
            <ul>
              <li>info@lsqa.com | marketing@lideco.com</li>
              <li>2600 0165 | 2908 1636</li>
              <li>
                Av. Italia 6201 | Edificio “Los Tilos” piso 1, 11500, Montevideo
                <span> Uruguay Julio Herrera y Obes 1413, Montevideo - Uruguay</span>
              </li>
              <li>
              <a href="https://lsqa.com.uy/" target="blank">www.lsqa.com.uy</a> 
              | 
              <a href="https://lideco.com/" target="blank">www.lideco.com</a>
                
              </li>
            </ul>
          </div>
          <div className="footer-logos">

              <div className="g-logo">
                
                  <StaticImage
                    src="../assets/images/g-white.svg"
                    alt="Deres"
                    placeholder="blurred"
                  />
                
              </div>
              <div className="other-logos">
         
                  <a href="https://lsqa.com.uy/" target="blank">
                    <StaticImage
                      src="../assets/images/lsqa-white.svg"
                      alt="LSQA"
                      placeholder="blurred"
                    />
                  </a>
              
              
                  <a href="https://www.iqnet-certification.com/" target="blank">
                    <StaticImage
                      src="../assets/images/iqnet-white.svg"
                      alt="IQNET"
                      placeholder="blurred"
                    />
                  </a>
          
              </div>
          </div>
        </div>
   
    </footer>
  )
}

export default Footer
