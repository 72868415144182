import React, { useState, useEffect } from "react";
import { FaGripLines } from "react-icons/fa";
import Modal from "react-modal";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import linksNav from "../constants/linksNav";

const Navbar = ({ toggleSidebar }) => {

  const browser = typeof window !== "undefined" && window;

    const [pagePath, setPagePath] = useState(0);
    useEffect(() => {
      setPagePath(browser.location.pathname);
    });


  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "0",
      border: "none",
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <nav
        className={`banner ${pagePath == "/" ? "navbar" : "navbar navbar-red"}`}
      >
        <div className="nav-center">
          <div className="nav-header">
            <a href="/" className="nav-logos">
                <StaticImage
                  src="../assets/images/logotipo.svg"
                  alt="+G - Programa de Gobernanza"
                  placeholder="blured"
                />
            </a>

            <ul className="navbar-links">
              {linksNav.map((link) => {
                return (
                  <li key={link.id}>
                    <a href={link.url}>{link.text}</a>
                  </li>
                );
              })}

              <li>
                <a onClick={openModal}>Acceso para Empresas</a>
              </li>
            </ul>
            <button
              type="button"
              className="toggle-btn"
              onClick={toggleSidebar}
            >
              <FaGripLines />
            </button>
          </div>
        </div>
      </nav>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="btn-close" onClick={closeModal}>
          close
        </button>
        <div className="lineas-g lineas-g-red">
          <div className="g-modal">
            <StaticImage
              src="../assets/images/g.svg"
              alt="+G"
              placeholder="blurred"
            />
          </div>
          <div className="modal-container">
            <p>Aún no disponible</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
